import React, { useRef } from 'react';
import NumberFormat from 'react-number-format';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
  TextField,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';
import {
  deepClone,
  isNotEmpty,
  orderByName,
  replaceSourcePath,
} from '../../../../../functions';

import * as S from './Styles';

const options = ['Espetinho', 'Porções', 'Pratos', 'Bebidas', 'Drinks'];

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            id: props.id,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="R$ "
    />
  );
});

function EditProduct({ open, data: initialData, showError, handleClosed }) {
  const theme = useTheme();

  const [data, setData] = React.useState(initialData);
  const [loading, setLoading] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [menu, setMenu] = React.useState([]);
  const [menuList, setMenuList] = React.useState([]);
  const [menuListRef, setMenuListRef] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [onReload, setReload] = React.useState(false);

  const inputFileRef = useRef(null);
  const handleClick = () => inputFileRef.current && inputFileRef.current.click();

  React.useEffect(() => setCategoryList([data.category]), [data.category]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Api.get('/menu/custom');
      const organizedResult = response
        .filter((item) => item._id !== data._id)
        .sort(orderByName);

      setMenuListRef(organizedResult);
      setMenuList(organizedResult.map((e) => e.name));
      setMenu(data?.combo?.map((item) => item.name) || []);
    };

    fetchData();
  }, [data]);

  React.useEffect(() => {}, [onReload]);

  const hasComplementName = (name) => (name.includes('Nº1') ? name : `${name} Nº1`);

  const removeComplementName = (name) =>
    name.includes('Nº1') ? name.replace('Nº1', '').trim() : name;

  const handleFileSelect = ({ target }) => setSelectedFile(target.files[0]);

  const updateProduct = async (event, data) => {
    event.preventDefault();
    setLoading(true);

    const error = [];

    if (data.combos_released > 0 && data.combo.length === 0) {
      error.push(`Selecione os itens liberados para o combo`);
    }

    if (error.length > 0) {
      setLoading(false);
      showError(error[0]);

      return;
    }

    try {
      const bodyData = {
        name:
          data.combos_released > 0
            ? hasComplementName(data.name)
            : removeComplementName(data.name),
        price: data.price,
        price_of: data.price_of,
        category: data.category,
        description: data.description,
        combos_released: data.combos_released,
        updated_by: localStorage.getItem('user_id'),
        combo: data.combos_released > 0 ? JSON.stringify(data.combo) : [],
      };

      const result = await Api.post(`/menu/edit?id=${data._id}`, bodyData);

      if (selectedFile !== null) {
        const formData = new FormData();

        formData.append('image', selectedFile);

        await Api.post(`/link-image/${result._id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }

      window.location.reload();
    } catch ({ message }) {
      setLoading(false);
      showError(message);
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = value || null;

    setData(cloneData);
  };

  const handleChangeSelect = ({ target: { name, value } }) => {
    setCategoryList(typeof value === 'string' ? value.split(',') : value);
    data[name] = value;
  };

  const handleChangeMenu = ({ target: { name, value } }) => {
    setMenu(typeof value === 'string' ? value.split(',') : value);
    const comboList = menuListRef.filter((item) => value.includes(item.name));

    data[name] = comboList.map((item) => ({
      ...item,
      price: 0,
      amount: 1,
      description: ['(ITEM DE COMBO)', item.description]
        .filter((item) => isNotEmpty(item))
        .join(' - '),
    }));

    setReload(!onReload);
  };

  return (
    <Dialog open={open} onClose={handleClosed} fullScreen>
      <DialogTitle>Editar Produto</DialogTitle>
      <DialogContent>
        <DialogContentText>Adicione ou atualize os dados do produto.</DialogContentText>
        <form id="formEdit" onSubmit={(e) => updateProduct(e, data)}>
          <TextField
            autoFocus
            fullWidth
            id="name"
            type="text"
            margin="dense"
            required
            label="Nome do Produto"
            defaultValue={data.name}
            onChange={handleChange}
          />
          <div style={{ display: 'flex', justfyContent: 'Center' }}>
            <TextField
              id="price"
              label="Preço"
              margin="dense"
              required
              fullWidth
              onChange={handleChange}
              defaultValue={data.price}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            <S.Divider />
            <TextField
              id="price_of"
              label="Promoção"
              margin="dense"
              fullWidth
              onChange={handleChange}
              defaultValue={data.price_of}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </div>

          <FormControl fullWidth margin="dense">
            <InputLabel id="category-label">Categoria</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              name="category"
              required
              value={categoryList}
              onChange={handleChangeSelect}
              input={<OutlinedInput label="Categoria" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              {options.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={{
                    fontWeight:
                      categoryList.indexOf(name) === -1
                        ? theme.typography.fontWeightRegular
                        : theme.typography.fontWeightMedium,
                  }}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            id="combos_released"
            type="number"
            margin="dense"
            required
            label="Combos liberados"
            defaultValue={data.combos_released}
            onChange={handleChange}
            placeholder="Insira a quatidade de combos liberados"
          />

          {data.combos_released > 0 && (
            <FormControl fullWidth margin="dense">
              <InputLabel id="combo_items">Itens liberados para o combo</InputLabel>
              <Select
                labelId="combo_items"
                id="combo"
                name="combo"
                multiple
                value={menu}
                onChange={handleChangeMenu}
                input={<OutlinedInput label="Itens liberados para o combo" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {menuList.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={menu.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            type="text"
            margin="dense"
            id="description"
            fullWidth
            label="Descrição"
            onChange={handleChange}
            defaultValue={data.description}
          />

          <S.Uploader
            htmlFor="contained-button-file"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              ...(data?.image && {
                backgroundImage: `url(${replaceSourcePath(data.image)})`,
              }),
            }}
          >
            <S.Input
              ref={inputFileRef}
              accept="image/*"
              id="contained-button-file"
              multiple={false}
              onChange={handleFileSelect}
              type="file"
              style={{ display: 'none' }}
            />
            <S.Upload LinkComponent="span" variant="outlined" onClick={handleClick}>
              {selectedFile?.name
                ? selectedFile?.name
                : data.image
                  ? data.image.split('/')[2]
                  : 'Adicionar imagem'}
            </S.Upload>
          </S.Uploader>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClosed}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="formEdit"
          endIcon={loading ? <MaterialIcon name="check" /> : null}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {loading ? 'Atualizando...' : 'Atualizar'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default EditProduct;
