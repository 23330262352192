import * as S from './Styles';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import Alerts from '../../../../../components/Alert';
import MaterialIcon from '../../../../../components/MaterialIcon';
import Api from '../../../../../connections/Api';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import { deepClone, orderByGroupName, orderByName } from '../../../../../functions';

const initialData = {
  orders: [],
  table: '',
  note: '',
  order_group: null,
  clients_data: [],
  internal_request: false,
};

const defaultMessageError =
  'Erro ao realizar esta ação, verifique os dados e tente novamente!';

const filter = createFilterOptions();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Create({ open, handleClosed, successCreateItem }) {
  const theme = useTheme();

  const [data, setData] = React.useState(initialData);

  const [loading, setLoading] = React.useState(false);

  const [menu, setMenu] = React.useState([]);
  const [menuList, setMenuList] = React.useState([]);
  const [menuListRef, setMenuListRef] = React.useState([]);

  const [ordersList, setOrdersList] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [alert, setAlert] = React.useState({ open: false, message: '' });

  const [value, setValue] = React.useState(null);
  const [optionsData, setOptions] = React.useState([]);

  const [employeeOptions, setEmployeeOptions] = React.useState([]);
  const [employeeSelected, selectEmployee] = React.useState('');

  const isNotAdmin = () => localStorage.getItem('user_level_name') !== 'Funcionário';

  const [internalRequest, setInternalRequest] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const [result, resultData, employeesList] = await Promise.all([
        Api.get('/menu/custom'),
        Api.get('/order/list'),
        Api.get('/challenge/able/employees'),
      ]);

      const organizedResult = result.sort(orderByName);
      const organizedResultData = resultData.data
        .filter((item) => 'order_group' in item)
        .filter(({ order_group }) => order_group !== null)
        .sort(orderByGroupName);
      const employeesListResult = employeesList.map(({ name }) => name).sort(orderByName);

      setEmployeeOptions(employeesListResult);
      setMenuListRef(organizedResult);
      setOrdersList(data?.orders || []);
      setMenuList(organizedResult.map((e) => e.name));
      setMenu(data?.orders?.map((item) => item.name) || []);
      setOptions(
        [...new Set(organizedResultData.map(({ order_group }) => order_group))].map(
          (title) => ({ title }),
        ),
      );
    };

    fetchData();
  }, []);

  React.useEffect(() => {}, [reload]);

  const showError = (message = defaultMessageError) => {
    setAlert({
      ...alert,
      open: true,
      status: 'error',
      message,
    });
  };

  const createOder = async (event, data) => {
    event.preventDefault();
    setLoading(true);

    const error = [];

    try {
      ordersList.forEach((item) => {
        if (
          'selecteds' in item &&
          item.selecteds.length > 0 &&
          item.combos_released > 0
        ) {
          const comboValues = item.selecteds
            .map((item) => item.amount)
            .reduce((total, curr) => total + curr, 0);

          if (comboValues > item.amount * item.combos_released) {
            error.push(
              `O item ${item.name} só permite ${
                item.amount * item.combos_released
              } itens no combo.`,
            );
          }
        } else if (item.combos_released > 0) {
          error.push(`Selecione ao menos uma opção de combo ao item:  ${item.name}`);
        }
      });

      if (error.length > 0) {
        setLoading(false);
        showError(error[0]);

        return;
      }

      const invalidAmount = ordersList.filter(
        ({ amount }) => amount === null || amount === undefined,
      );

      if (invalidAmount.length) {
        const message = invalidAmount.map(({ name }) => name).join(', ');
        setLoading(false);

        return showError(`A quantidade dos itens ${message} é inferior a 0`);
      }

      if (data.table.length === 0) {
        setLoading(false);
        return showError(`Nome da mesa inválido`);
      }

      const bodyData = {
        status: 0,
        note: data.note,
        closed_by: null,
        clients_data: [],
        internal_request: internalRequest,
        orders: ordersList,
        table: data.table,
        order_group: data.order_group,
      };

      const { message } = await Api.post(`/order/create`, bodyData);

      successCreateItem(message);

      setTimeout(() => window.location.reload(), 1000);
    } catch ({ status, message }) {
      setLoading(false);

      if (status === 404) {
        return showError(message);
      }

      showError();
    }
  };

  const handleChange = ({ target: { id, value } }) => {
    const cloneData = deepClone(data);

    cloneData[id] = value || null;

    setData(cloneData);
  };

  const handleChangeMenu = ({ target: { name, value } }) => {
    setMenu(typeof value === 'string' ? value.split(',') : value);
    data[name] = menuListRef.filter((item) => value.includes(item.name));

    const listItens = menuListRef
      .filter((item) => value.includes(item.name))
      .map((e) => {
        delete e._id;

        const indexList = data.orders.map((el) => el.name).indexOf(e.name);
        const indexList2 = menuListRef.map((el) => el.name).indexOf(e.name);

        const itemSelected =
          indexList !== -1 ? data.orders[indexList] : menuListRef[indexList2];

        e.to = null;
        e.ready = itemSelected.ready || false;
        e.amount = itemSelected.amount || 1;
        e.price = itemSelected.price;

        if ('selecteds' in itemSelected) {
          e.selecteds = itemSelected.selecteds;
        }

        return e;
      });

    setOrdersList(listItens);
    setReload(!reload);
  };

  const handleChangeCombo = (value, comboItem) => {
    comboItem.selecteds = comboItem.combo.filter((item) => value.includes(item.name));

    setReload(!reload);
  };

  const handleAddAmount = (id, value, order) => {
    value++;

    ordersList.forEach((item, index) => {
      if (item.name === id) {
        if ('combo' in order && order.combo.length > 0) {
          const newObject = deepClone(order);

          newObject.selecteds = [];

          if (order.name.includes(' Nº')) {
            const names = order.name.split(' Nº');
            newObject.name = `${names[0]} Nº${+names[1] + 1}`;
          }

          newObject.combo.forEach((item, key) => {
            newObject.combo[key].amount = 1;
          });

          menu.push(newObject.name);
          ordersList.push(newObject);
          menuListRef.push(newObject);

          setMenu(menu);
          setMenuList(menuListRef.map((e) => e.name));
          setMenuListRef(menuListRef.sort(orderByName));
        } else {
          ordersList[index].amount = +value;
        }
      }
    });

    setOrdersList(ordersList);
    setReload(!reload);
  };

  const handleRemoveAmount = (id, value, order) => {
    if (value > 1) {
      value--;
    }

    ordersList.forEach((item, index) => {
      if (item.name === id) {
        if ('combo' in order && order.combo.length > 0) {
          ordersList.splice(index, 1);

          const menuListRefIndex = menuListRef
            .map((listRef, keyIndex) =>
              listRef.name === item.name ? keyIndex : undefined,
            )
            .filter(Boolean)[0];

          menu.splice(menu.indexOf(item.name), 1);
          menuListRef.splice(menuListRefIndex, 1);
          menuList.splice(menuList.indexOf(item.name), 1);

          setMenu(menu);
          setMenuList(menuListRef.map((e) => e.name));
          setMenuListRef(menuListRef.sort(orderByName));
        } else {
          ordersList[index].amount = +value;
        }
      }
    });

    setOrdersList(ordersList);
    setReload(!reload);
  };

  const handleAddComboAmount = (itemSelected, key, value, keyList) => {
    const comboValues = itemSelected.selecteds
      .map((item) => item.amount)
      .reduce((total, curr) => total + curr, 0);

    if (comboValues < itemSelected.amount * itemSelected.combos_released) {
      value++;

      ordersList[keyList].selecteds[key].amount = value;

      setReload(!reload);
    }
  };

  const handleRemoveComboAmount = (itemSelected, key, value, keyList) => {
    value--;

    if (value > 0) {
      ordersList[keyList].selecteds[key].amount = value;

      setReload(!reload);
    }
  };

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };

  const handleChangeSelect = ({ target: { value } }) => {
    selectEmployee(value);

    data.table = value;
  };

  return (
    <Dialog open={open} onClose={handleClosed} fullScreen>
      <Alerts
        open={alert.open}
        handleClose={handleClose}
        message={alert.message}
        status={alert.status}
      />
      <DialogTitle>NOVA COMANDA</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginBottom: 3 }}>
          Criação da comanda da mesa.
        </DialogContentText>

        <Divider />

        {isNotAdmin() && (
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <p>Vincular a um funcionário</p>
            <Checkbox
              name="internalRequest"
              checked={internalRequest}
              onChange={() => setInternalRequest((current) => !current)}
            />
          </div>
        )}

        <form id="formEdit" onSubmit={(e) => createOder(e, data)}>
          {!internalRequest && (
            <>
              <Autocomplete
                value={value}
                margin="dense"
                fullWidth
                style={{ marginTop: '5px' }}
                onChange={(e, newValue) => {
                  if (typeof newValue === 'string') {
                    setValue({ title: newValue });
                    handleChange({
                      target: { id: 'order_group', value: newValue.title },
                    });
                  } else if (newValue && newValue.inputValue) {
                    setValue({ title: newValue.inputValue });
                    handleChange({
                      target: { id: 'order_group', value: newValue.inputValue },
                    });
                  } else {
                    setValue(newValue);
                    handleChange({
                      target: { id: 'order_group', value: newValue.title },
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  const filtered = filter(options, params);
                  const isExisting = options.some(
                    (option) => inputValue === option.title,
                  );

                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Criar grupo: "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="order_group"
                options={optionsData}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.title;
                }}
                freeSolo
                renderOption={(props, option) => <li {...props}>{option.title}</li>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Grupo"
                    placeholder="Agrupe as comanda por grupo"
                  />
                )}
              />

              <TextField
                id="table"
                type="text"
                label={!internalRequest ? 'Nome da Comanda' : 'Nome do funcionário'}
                margin="dense"
                onChange={handleChange}
                fullWidth
                defaultValue={data?.table}
              />
            </>
          )}
          {internalRequest && (
            <S.Row item>
              <FormControl margin="dense" fullWidth>
                <InputLabel id="group-label">Funcionários</InputLabel>
                <Select
                  labelId="group-label"
                  id="group"
                  name="group"
                  required
                  value={employeeSelected}
                  onChange={handleChangeSelect}
                  input={<OutlinedInput label="Funcionários" />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 150,
                      },
                    },
                  }}
                >
                  {employeeOptions.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={{
                        fontWeight:
                          [employeeSelected].indexOf(name) === -1
                            ? theme.typography.fontWeightRegular
                            : theme.typography.fontWeightMedium,
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </S.Row>
          )}

          <Autocomplete
            id="promotional_menu"
            name="promotional_menu"
            multiple
            options={menuList}
            disableCloseOnSelect
            clearIcon={false}
            value={menu}
            onChange={(e, newValue) => {
              handleChangeMenu({
                target: { name: 'promotional_menu', value: newValue },
              });
            }}
            style={{ marginTop: '10px' }}
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cardápio" placeholder="Cardápio" />
            )}
          />

          {ordersList.map((item, keylist) => (
            <React.Fragment key={reload ? keylist : item.name + keylist}>
              <div
                style={{
                  padding: 10,
                  borderRadius: 5,
                  marginBottom: 8,
                  display: 'flex',
                  marginTop: '10px',
                  flexDirection: 'column',
                  border: 'gray solid 1.5px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    id="item"
                    type="text"
                    label="Pedido"
                    margin="dense"
                    disabled
                    maxRows={2}
                    multiline
                    fullWidth
                    defaultValue={item.name}
                  />
                  <S.Divider />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {'combo' in item &&
                    item.combo.length > 0 &&
                    item.name.includes(' Nº1') ? null : (
                      <Button
                        variant="contained"
                        style={{ height: 53, width: '40%' }}
                        color="error"
                        onClick={() => handleRemoveAmount(item.name, item.amount, item)}
                      >
                        -
                      </Button>
                    )}
                    {'combo' in item &&
                    item.combo.length > 0 &&
                    item.name.includes('Nº1') ? null : (
                      <span
                        style={{
                          fontSize: 20,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {item.amount}
                      </span>
                    )}

                    <Button
                      variant="contained"
                      style={{
                        height: 53,
                        width:
                          'combo' in item &&
                          item.combo.length > 0 &&
                          item.name.includes('Nº1')
                            ? '100%'
                            : '40%',
                      }}
                      color="success"
                      onClick={() => handleAddAmount(item.name, item.amount, item)}
                      disabled={
                        !!ordersList.find(
                          (ordes) =>
                            ordes.name ===
                            `${item.name.split('Nº')[0]}Nº${
                              +item.name.split('Nº')[1] + 1
                            }`,
                        )
                      }
                    >
                      {'combo' in item && item.combo.length > 0
                        ? `Adicionar combo de ${item.name.split('Nº')[0]}Nº${
                            +item.name.split('Nº')[1] + 1
                          }`
                        : '+'}
                    </Button>
                  </div>
                </div>
                {'combo' in item && item.combo.length > 0 ? (
                  <>
                    <Autocomplete
                      id="combo"
                      name="combo"
                      multiple
                      clearIcon={false}
                      disableCloseOnSelect
                      style={{ marginTop: '10px' }}
                      getOptionLabel={(option) => option}
                      options={item.combo.map(({ name }) => name)}
                      value={item?.selecteds?.map((item) => item.name) || []}
                      onChange={(e, value) => handleChangeCombo(value, item)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Selecione até ${item.combos_released} itens`}
                          placeholder={`Selecione até ${item.combos_released} itens`}
                        />
                      )}
                    />

                    {(item?.selecteds || []).map((itemSelected, key) => (
                      <React.Fragment key={reload ? key : itemSelected.name + key}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <TextField
                            id="item"
                            type="text"
                            label="Pedido"
                            margin="dense"
                            disabled
                            maxRows={2}
                            multiline
                            fullWidth
                            defaultValue={itemSelected.name}
                          />
                          <S.Divider />
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{ height: 53, width: '40%' }}
                              color="error"
                              onClick={() =>
                                handleRemoveComboAmount(
                                  item,
                                  key,
                                  itemSelected.amount,
                                  keylist,
                                )
                              }
                            >
                              -
                            </Button>
                            <span
                              style={{
                                fontSize: 20,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              {itemSelected.amount}
                            </span>
                            <Button
                              variant="contained"
                              style={{ height: 53, width: '40%' }}
                              color="success"
                              onClick={() =>
                                handleAddComboAmount(
                                  item,
                                  key,
                                  itemSelected.amount,
                                  keylist,
                                )
                              }
                            >
                              +
                            </Button>
                          </div>
                        </div>
                        <S.Divider />
                      </React.Fragment>
                    ))}
                  </>
                ) : null}
              </div>
            </React.Fragment>
          ))}

          <TextField
            rows={3}
            id="note"
            type="text"
            margin="dense"
            multiline
            fullWidth
            label="Descrição"
            onChange={handleChange}
            defaultValue={data?.note}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClosed}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          form="formEdit"
          endIcon={loading ? <MaterialIcon name="check" /> : null}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          {loading ? 'Registrando...' : 'Registrar comanda'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
